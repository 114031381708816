i.event {
    transform: rotate(0deg);
}


i.event-1 {
    transform: rotate(-1deg);
}

i.event-2 {
    transform: rotate(-2deg);
}

i.event1 {
    transform: rotate(1deg);
}

i.event2 {
    transform: rotate(2deg);
}

i.empty {
    color: transparent;
}

i.hot-spot {
    color:red; 
    transform: rotate(-35deg);
}
