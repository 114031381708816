.main
{
  background-color: $bg-main;
  text-align: center;

  .postit
  {
    line-height: 1;
    text-align: center;
    color: black;
    font-weight: bold;
    min-width: 40%;
    max-width: 100%;

    min-height: 100%;
    max-height: 100%;
    position: relative;
    border: 1px solid orange;
    border-top: 40px solid #fdfd86;
    border-bottom: 40px solid orange;
    font-size: 3vw;
    border-bottom-right-radius: 60px 5px;
    display: inline-block;
    background: orange;
    background: linear-gradient(135deg, orange 81%,orange 82%,orange 82%,orange 100%);
    border-top-color: orange;
  }

  .big-text
  {
    font-size: 3vw;
    font-weight: bold;
    text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;
    text-transform: uppercase;
    

    &-ten
    {
      font-size: 100px;
    }
  }

  .bold-text
  {
    font-size: 4vw;;
    font-weight: bold;
    color: black;
  }

  .command-color
  {
    color: #25abfe;
  }
}

.eventstorming
{
  background: #fde8c0;
  padding: 0px;
  padding-bottom:20px;

  h3
  {
    font-weight: 400;
    padding:0px;
  }

  &-info
  {
    line-height: 1.5em;
    text-align: center;
    font-size: 20px;
    font-weight: 300;
    padding: 0 15px;
  }

  &-image
  {
    display: inline-block;
    border-radius: 50%;
    border: 4px dotted;
  }

  .arrow
  {
    &-down
    {
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #888;
      margin: 2px auto 2px;
    }

    &-up
    {
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #888;
      margin: 2px auto 2px;
    }
  }

  .special-icon
  {
    color: orange;
    border: 2px dotted orange;
    border-radius: 100%;
    width: 100px;
    height: 100px;
    padding-top: 15px;
    font-size: 46px;
    text-align: center;
    vertical-align: middle;
    margin: 19px auto;
  }

  .border
  {
    border-radius: 10px;
    border: 4px dotted #888;
    padding: 10px;
    margin: 10px;
  }

  .virticle-line
  {
    height: 40px;
    width: 2px;
    margin: 0 auto;
    border-left: 4px dotted #888;
  }
}
.book {
  display: block;
  max-width: 100%;
  min-width: 100%;
  max-height: 1000px;
  margin: 0 auto;
}

.author
{
  background: #fde8c0;
  padding: 0px;
  padding-top:20px;

  h3
  {
    font-weight: 400;
  }

  &-info
  {
    line-height: 1.5em;
    text-align: center;
    font-size: 20px;
    font-weight: 300;
    padding: 0 15px;
  }

  &-image
  {
    display: block;
    border-radius: 50%;
    border: 4px dotted;
    max-width: 90%;
    
    margin: 0 auto;
  }

  .arrow
  {
    &-down
    {
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #888;
      margin: 2px auto 2px;
    }

    &-up
    {
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #888;
      margin: 2px auto 2px;
    }
  }

  .special-icon
  {
    color: orange;
    border: 2px dotted orange;
    border-radius: 100%;
    width: 100px;
    height: 100px;
    font-size: 46px;
    text-align: center;
    
    margin: 19px auto;
    padding-top: 20px;
  }

  .border
  {
    border-radius: 10px;
    border: 4px dotted #888;
    padding: 10px;
    margin: 10px;
  }

  .virticle-line
  {
    height: 40px;
    width: 2px;
    margin: 0 auto;
    border-left: 4px dotted #888;
  }
}

.download
{
  background: white;

  .information {
    font-size: 32px !important;
    text-align: center;
  }

  .form-download
  {

    input
    {
      border-width: 0 !important;
      border-bottom: 2px solid #000 !important;
      border-radius: 0 !important;
      transition: all 0.3s;
      font-size: 22px !important;
      color: #666666 !important;
      height: 47px;
      font-weight: 300 !important;
      padding: 0;
      box-shadow: none;
      background: transparent;
      display: block;
      width: 95%;
      outline: 0;
      margin-bottom: 20px;

      &:focus
      {
        outline: 0;
      }
    }

    &-fields
    {
      display: flex;
      flex-wrap: wrap;
      margin: 0 auto;
    }

    &-field
    {
      -webkit-flex: 100 1 auto;
      -ms-flex: 100 1 auto;
      flex: 100 1 auto;
      width: 65%;
    }

    &-submit
    {
      background-color: orange !important;
      color: white;
      letter-spacing: 1px;
      font-size: 40px;
      font-weight:bold;
      
      padding: 19px 24px;
      line-height: 60px;
      border: 1px solid #1a1a1a;
      flex: none !important;
      width: 100%;
      margin: 0 auto;
      display: block;
      border-radius: 0 !important;
      cursor: pointer;
      transition: all .3s;

      &:hover
      {
        background-color: #aae700 !important;
        color: black !important;
        transition: all .3s;
      }
    }
  }
}


ul.opportunities
{
  display: block;
  list-style-type: disc;
  margin-top: 1em;
  margin-bottom: 1 em;
  margin-left: 0;
  margin-right: 0;
  padding-left: 40px;

  li
  {
    display: list-item;
  }
}