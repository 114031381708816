body
{
  font-family: 'Roboto', sans-serif;
}

h2
{
  font-size: 48px;
  font-weight: 300;
  margin: 0;
  padding-bottom: 50px;
  text-align: center;

  strong
  {
    font-weight: 600;
  }
}




h3
{
  font-size: 30px;
  line-height: 45px;
  font-weight: 300;
  margin: 0;
  padding-bottom: 50px;
  text-align: center;
}

section
{
  padding: 30px 0;
}

.subtitle
{
  font-size: 14px;
  letter-spacing: 1.5px;
  padding-bottom: 30px;
  text-align: center;
  text-transform: uppercase;
}

.img-fluid
{
  max-width: 100%;
  height: auto;
}

.panel
{
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
  border-color: rgba(223, 225, 229, 0);
  margin-bottom: 20px;

  &-header
  {
    font-family: 'Caveat', cursive;
    font-size: 64px;
    text-align: center;
  }

  &-body
  {
    p
    {
      font-size: 12px;
      color: gray;
      margin: 0;
      line-height: 1.4em;
    }
  }
}