footer
{
  background-color: $bg-footer;
  color: $color-footer;
  padding: 20px 0;
  text-align: center;
  font-weight: 300;
  font-size: 13px;
  line-height: 2em;

  a
  {
    color: $color-main;
  }
}