.header
{
  position: relative;
  background: #fff;
  border-bottom: 1px solid #f5f5f5;
  padding: 15px 0;

  h1
  {
    font-weight: 300;
    margin: 0;
    color: #424242;
    padding-right: 20px;
    text-align: left;
    font-size: 36pt;

    strong
    {
      font-weight: 500;
    }
  }

  ul.menu
  {
    margin: 0;
    list-style: none;
    padding-left: 0;
    
    li
    {
      display: inline-block;

      .postit-menu
      {
        line-height: 1;
        text-align: center;
        text-decoration: none;
        width: 100px;
        margin: 0 20px;
        min-height: 100px;
        max-height: 1000px;
        padding-top: 0;
        position: relative;
        border: 1px solid #E8E8E8;
        border-top: 36px solid #fdfd86;
        font-family: 'Reenie Beanie';
        color: #000;
        font-size: 31px;
        border-bottom-right-radius: 60px 5px;
        display: inline-block;
        background: #ffff88;
        background: linear-gradient(135deg, #ffff88 81%,#ffff88 82%,#ffff88 82%,#ffffc6 100%);

        &:after
        {
          content: "";
          position: absolute;
          z-index: -1;
          right: -0px;
          bottom: 20px;
          width: 75px;
          height: 25px;
          background: rgba(0, 0, 0, 0.2);
          box-shadow: 2px 15px 5px rgba(0, 0, 0, 0.40);
          transform: matrix(-1, -0.1, 0, 1, 0, 0);
        }

        &.green
        {
          background: #1eca16;
          background: linear-gradient(135deg, #ace816 81%,#ace816 82%,#ace816 82%,#ace816 100%);
          border-top-color: #ace816;
        }

        &.red
        {
          background: DeepPink ;
          background: linear-gradient(135deg, DeepPink  81%,DeepPink  82%,DeepPink  82%,DeepPink  100%);
          border-top-color: DeepPink ;
        }

        &.blue {
          background: #25abfe;
          background: linear-gradient(135deg, #25abfe 81%, #25abfe 82%, #25abfe 82%, #25abfe 100%);
          border-top-color: #25abfe;
        }
      }
    }
  }

  .logo a
  {
    font-family: 'Noto Serif', serif;
    display: block;
    color: $color-main;
    text-decoration: none;
    text-transform: uppercase;
    line-height: 1em;
    font-weight: 500;
    font-size: 25px;
    width: 100px;
    margin: 0 auto;

    span
    {
      display: block;
      color: #000000;
    }
  }
}

.what-is-event-storming {
  font-family: 'Noto Serif', serif;
  font-weight: 500;
  font-size: 20pt;
  background-color: $bg-what-is-event-storming;


  a:link {
    color: black; 
    background-color: transparent; 
    text-decoration: none;
  }
  
  a:visited {
    color: black;
    background-color: transparent;
    text-decoration: none;
  }
  
  a:hover {
    color: red;
    background-color: transparent;
    text-decoration: none;
  }
  
  a:active {
    color: yellow;
    background-color: transparent;
    text-decoration: none;
  }
}